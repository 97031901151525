import {httpNewApi, httpApiV3, useVzFetch} from '~/composables/useVzFetch';
import {Meta} from '~/types/types';
import {
  ActionListRequest,
  EventRecord,
  PersonalOffer,
} from '~/types/components/personal/actions';
import {VZResponse} from '~/types/api';

export function getEvents(requestData: ActionListRequest) {
  return useVzFetch(
      `${httpNewApi}/personal/event-list/get`,
      { method: 'post', body: requestData }
  ) as Promise<{ data: EventRecord[], meta: Meta }>
}

export function getOfferList() {
  return useVzFetch<PersonalOffer[]>(`${httpApiV3}/personal/offer`)
}

export function updateOffer(guid: string, body?: Partial<PersonalOffer>) {
  return useVzFetch<VZResponse<Partial<PersonalOffer>>>(
      `${httpApiV3}/personal/offer/${guid}`,
      { method: 'PUT', body }
  )
}
